:root {
  --color-white: #efefef;
  --color-black: #3f3f3f;
  --color-green: #8faf9f;
  --color-red: #dca3a3;
  --color-yellow: #f0dfaf;
  --fonts-main: "Sofia Sans", AppleGothic, sans-serif;
  --pad-xs: .2rem;
  --pad-s: .4rem;
  --pad-m: .8rem;
  --pad-l: 1.6rem;
  --pad-xl: 3.2rem;
  --pad-xxl: 6.4rem;
  --font-xs: .8rem;
  --font-s: .9rem;
  --font-m: 1rem;
  --font-l: 1.8rem;
  --font-xl: 3.6rem;
}

@media print {
  :root {
    --font-xs: .8rem;
    --font-s: .9rem;
    --font-m: 1rem;
    --font-l: 1.2rem;
    --font-xl: 1.8rem;
  }
}

html {
  font-family: var(--fonts-main);
  font-size: 1.4em;
}

@media print {
  html {
    font-size: 1.2em;
  }
}

body {
  background-color: var(--color-white);
  font-family: var(--fonts-main);
  color: var(--color-black);
  padding-top: var(--pad-xxl);
}

@media print {
  body {
    background-color: #0000;
    padding-top: 0;
  }
}

@media only screen and ((device-width <= 812px) or (width <= 812px)) {
  body {
    padding-top: var(--pad-xl);
  }
}

.container {
  max-width: 720px;
  margin: 0 auto;
}

@media only screen and ((device-width <= 812px) or (width <= 812px)) {
  .container {
    max-width: 812px;
    padding-left: var(--pad-m);
    padding-right: var(--pad-m);
  }
}

a {
  color: var(--color-green);
  text-decoration: none;
}

a:hover, a:active {
  color: var(--color-red);
  text-decoration: none;
}

@media print {
  a {
    color: var (--color-black);
  }

  a:hover, a:active {
    color: var(--color-black);
  }
}

header {
  margin-bottom: var(--pad-l);
  page-break-inside: avoid;
}

header h1 {
  font-size: var(--font-xl);
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
}

header h3 {
  font-size: var(--font-s);
  margin-top: 0;
  margin-bottom: var(--pad-l);
  font-weight: normal;
}

main {
  margin-bottom: var(--pad-xl);
  font-size: var(--font-m);
}

nav {
  font-size: var(--font-m);
  font-family: var(--fonts-main);
  margin-bottom: var(--pad-xl);
  padding-left: 0;
}

nav a {
  margin-bottom: var(--pad-s);
  display: block;
}

nav a span {
  display: inline-block;
}

nav a span.icon {
  width: var(--pad-m);
  height: var(--pad-m);
  margin-left: 0;
  display: inline-block;
}

nav a span.icon svg {
  vertical-align: middle;
  margin: 0;
  padding: 0;
}

nav a span.label {
  color: var(--color-black);
  width: var(--pad-xxl);
  margin-left: var(--pad-m);
  text-transform: lowercase;
}

.job {
  page-break-inside: avoid;
  margin-bottom: var(--pad-l);
}

.job h2, .job h3 {
  font-weight: bold;
  font-size: var(--font-l);
  margin-top: 0;
  margin-bottom: 0;
  display: block;
}

.job .period {
  color: var(--color-black);
  font-family: var(--fonts-main);
  font-size: var(--font-m);
  text-transform: lowercase;
  margin-top: 0;
  margin-bottom: 0;
  display: block;
}

.job .period .sep {
  text-align: center;
  width: var(--pad-m);
  display: inline-block;
}

.job ul.tags {
  margin-top: 0;
  margin-bottom: var(--pad-m);
  font-size: var(--font-s);
  padding-left: 0;
  display: block;
}

.job ul.tags li {
  margin-right: 0;
  margin-left: var(--pad-s);
  list-style-type: none;
  display: inline-block;
}

.job ul.tags li:first-child {
  margin-left: 0;
}

.job p.summary {
  font-family: var(--fonts-main);
  font-weight: normal;
  font-size: var(--font-m);
  margin-top: 0;
  margin-bottom: 0;
}

.job p.summary .company {
  font-weight: bold;
}

.job p.summary .sep {
  text-align: center;
  width: var(--pad-l);
  display: inline-block;
}

@media (device-width <= 812px) or (width <= 812px) {
  .job p.summary {
    text-align: justify;
    text-justify: auto;
  }
}

footer {
  margin-bottom: var(--pad-xxl);
  font-family: var(--fonts-main);
}

footer a {
  text-decoration: underline;
}

footer p {
  margin-top: 0;
  margin-bottom: var(--pad-l);
}

@media print {
  footer {
    display: none;
  }
}
/*# sourceMappingURL=index.4c91cad2.css.map */
