/* vim: filetype=css
 */

:root {
  /* REF: https://www.colourlovers.com/palette/1510720/Zenburn */
  --color-white: #EFEFEF;
  --color-black: #3F3F3F;
  --color-green: #8FAF9F;
  --color-red: #DCA3A3;
  --color-yellow: #F0DFAF;

  --fonts-main: "Sofia Sans", AppleGothic, sans-serif;

  --pad-xs: 0.2rem;
  --pad-s: 0.4rem;
  --pad-m: 0.8rem;
  --pad-l: 1.6rem;
  --pad-xl: 3.2rem;
  --pad-xxl: 6.4rem;

  --font-xs: 0.8rem;
  --font-s: 0.9rem;
  --font-m: 1.0rem;
  --font-l: 1.8rem;
  --font-xl: 3.6rem;
}
@media print {
  :root {
    --font-xs: 0.8rem;
    --font-s: 0.9rem;
    --font-m: 1.0rem;
    --font-l: 1.2rem;
    --font-xl: 1.8rem;
  }
}

html {
  font-family: var(--fonts-main);
  font-size: 1.4em;
}
@media print {
  html {
    font-size: 1.2em;
  }
}

body {
  background-color: var(--color-white);
  font-family: var(--fonts-main);
  color: var(--color-black);
  padding-top: var(--pad-xxl);
}
@media print {
  body {
    background-color: transparent;
    padding-top: 0;
  }
}
@media only screen and ((max-device-width: 812px) or (max-width: 812px)) {
  body {
    padding-top: var(--pad-xl);
  }
}

.container {
  margin: 0 auto;
  max-width: 720px;
}
@media only screen and ((max-device-width: 812px) or (max-width: 812px)) {
  .container {
    max-width: 812px;
    padding-left: var(--pad-m);
    padding-right: var(--pad-m);
  }
}

a {
  color: var(--color-green);
  text-decoration: none;
}
a:hover, a:active {
  color: var(--color-red);
  text-decoration: none;
}
@media print {
  a {
    color: var (--color-black);
  }
  a:hover, a:active {
    color: var(--color-black);
  }
}

header {
  margin-bottom: var(--pad-l);
  page-break-inside: avoid;
}
header h1 {
  font-size: var(--font-xl);
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0;
}
header h3 {
  font-size: var(--font-s);
  margin-top: 0;
  font-weight: normal;
  margin-bottom: var(--pad-l);
}

main {
  margin-bottom: var(--pad-xl);
  font-size: var(--font-m);
}

nav {
  font-size: var(--font-m);
  font-family: var(--fonts-main);
  padding-left: 0;
  margin-bottom: var(--pad-xl);
}
nav a {
  display: block;
  margin-bottom: var(--pad-s);
}
nav a span {
  display: inline-block;
}
nav a span.icon {
  display: inline-block;
  margin-left: 0;
  width: var(--pad-m);
  height: var(--pad-m);
}
nav a span.icon svg {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
nav a span.label {
  color: var(--color-black);
  width: var(--pad-xxl);
  margin-left: var(--pad-m);
  text-transform: lowercase;
}
nav a span.value {
}

.job {
  page-break-inside: avoid;
  margin-bottom: var(--pad-l);
}
.job h2, .job h3 {
  display: block;
  font-weight: bold;
  font-size: var(--font-l);
  margin-top: 0;
  margin-bottom: 0;
}
.job .period {
  color: var(--color-black);
  display: block;
  font-family: var(--fonts-main);
  font-size: var(--font-m);
  margin-top: 0;
  margin-bottom: 0;
  text-transform: lowercase;
}
.job .period .sep {
  text-align: center;
  display: inline-block;
  width: var(--pad-m);
}

.job ul.tags {
  display: block;
  margin-top: 0;
  padding-left: 0;
  margin-bottom: var(--pad-m);
  font-size: var(--font-s);
}
.job ul.tags li {
  display: inline-block;
  list-style-type: none;
  margin-right: 0;
  margin-left: var(--pad-s);
}
.job ul.tags li:first-child {
  margin-left: 0;
}

.job p.summary {
  margin-top: 0;
  margin-bottom: 0;
  font-family: var(--fonts-main);
  font-weight: normal;
  font-size: var(--font-m);
}
.job p.summary .company {
  font-weight: bold;
}
.job p.summary .sep {
  display: inline-block;
  text-align: center;
  width: var(--pad-l);
}
@media (max-device-width: 812px) or (max-width: 812px) {
  /* print and screen */
  .job p.summary {
    text-align: justify;
    text-justify: auto;
  }
}

footer {
  margin-bottom: var(--pad-xxl);
  font-family: var(--fonts-main);
}
footer a {
  text-decoration: underline;
}
footer p {
  margin-top: 0;
  margin-bottom: var(--pad-l);
}
@media print {
  footer {
    display: none;
  }
}
